import Pic1 from '../../images/pic01.jpg';
import Pic2 from '../../images/pic02.jpg';
import Pic3 from '../../images/pic03.jpg';

function Features() {
    return (
        <section id="features">
            <div class="container">
                <header>
                    <h2>Consensus, Applications and <strong>Scale</strong>!</h2>
                </header>
                <div class="row aln-center">
                    <div class="col-4 col-6-medium col-12-small">

                            <section>
                                <a href="/#" class="image featured"><img src={Pic1} alt="" /></a>
                                <header>
                                    <h3>Consensus, so what is this?</h3>
                                </header>
                                <p>A generally accepted opinion; wide  <strong>agreement</strong>, It is a process by which decentralized applications
                                (dapps) agree on the state of memory.</p>
                            </section>

                    </div>
                    <div class="col-4 col-6-medium col-12-small">

                            <section>
                                <a href="/#" class="image featured"><img src={Pic2} alt="" /></a>
                                <header>
                                    <h3>Nice! What are Dapps?</h3>
                                </header>
                                <p>Decentralized applications (<strong>dApps</strong>) are digital applications or programs that exist and run on 
                                    a blockchain or peer-to-peer (P2P) network of computers instead of a single computer.</p>
                            </section>

                    </div>
                    <div class="col-4 col-6-medium col-12-small">

                            <section>
                                <a href="/#" class="image featured"><img src={Pic3} alt="" /></a>
                                <header>
                                    <h3>Is it built to scale?</h3>
                                </header>
                                <p>Decentralized applications built can scale <strong>horizontally</strong> by <strong>parallel</strong> running application logic that
                                    commuinicates via messaging protocol between multiple application states. </p>
                            </section>

                    </div>
                    <div class="col-12">
                        <ul class="actions">
                            <li><a href="/readmore" class="button icon solid fa-file">Tell Me More</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;