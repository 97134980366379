import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Homepage from './components/homepage/Homepage';
import Readmore from './components/readmore/Readmore';
import Footer from './components/footer/Footer';

function App() {
  return (
    <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/index.html' element={<Homepage />} />
          <Route path='/readmore' element={<Readmore />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
