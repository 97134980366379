function Banner() {
    return (
        <section id="banner">
            <div class="container">
                <p>Built Upon Tested Technologies.</p>
            </div>
        </section>
    );
}

export default Banner;