import React from 'react';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
    
        this.setState({
          [name]: value
        });
    }

    render() {
        return (
        <section id="footer">
        <div class="container">
            <header>
                <h2>Questions or comments? <strong>Get in touch:</strong></h2>
            </header>
            <div class="row">
                <div class="col-6 col-12-medium">
                    <section>
                        <form method="post" action="/">
                            <div class="row gtr-50">
                                <div class="col-6 col-12-small">
                                    <input name="name" onChange={this.handleInputChange} placeholder="Name" type="text" />
                                </div>
                                <div class="col-6 col-12-small">
                                    <input name="email" onChange={this.handleInputChange} placeholder="Email" type="text" />
                                </div>
                                <div class="col-12">
                                    <textarea name="message" onChange={this.handleInputChange} placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <a href="/" onClick={() => {this.sendMessage(this.state.name.trim(), this.state.email.trim(), this.state.message.trim())} }
                                    class="form-button-submit button icon solid fa-envelope">
                                        Send Message
                                    </a>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div class="col-6 col-12-medium">
                    <section>
                        <p>We are passionate about innovation, delivery and relationship. Let's sit together to discuss your needs and maybe we can form a work relationship.</p>
                        <div class="row">
                            <div class="col-6 col-12-small">
                                <ul class="icons">
                                    <li class="icon solid fa-home">
                                        Calgary, Alberta<br />
                                        Canada
                                    </li>
                                    <li class="icon solid fa-envelope">
                                        <a href="/">talk@parabolicdapps.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6 col-12-small">
                                <ul class="icons">
                                    <li class="icon brands fa-twitter">
                                        <a href="/">@parabolicdapps</a>
                                    </li>
                                    <li class="icon brands fa-instagram">
                                        <a href="/">instagram.com/parabolicdapps</a>
                                    </li>
                                    <li class="icon brands fa-dribbble">
                                        <a href="/">dribbble.com/parabolicdapps</a>
                                    </li>
                                    <li class="icon brands fa-facebook-f">
                                        <a href="/">facebook.com/parabolicdapps</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div id="copyright" class="container">
            <ul class="links">
                <li>&copy; ParabolicDapps. All rights reserved.</li>
            </ul>
        </div>
        </section>
        );
    }

    sendMessage(name, email, message) {

        if (name.length === 0 || email.length === 0 || message.length === 0) {
            return;
        }

        var Airtable = require('airtable');
        var base = new Airtable({apiKey: 'key01PoOnAXNW6JFg'}).base('appXICsWZt2k37jR3');

        base('contactus').create([
        {
            "fields": {
            "Name": name,
            "Email": email,
            "Message": message
            }
        }
        ], function(err, records) {
        if (err) {
            console.error(err);
            return;
        }
        records.forEach(function (record) {
            console.log(record.getId());
        });
        });

    }
}

export default Footer;