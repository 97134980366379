import '../../css/main.css';

import Features from '../features/Features';
import Banner from '../banner/Banner';
import Products from '../products/Products';
import OpenSource from '../opensource/OpenSource';
import Technology from '../technology/Technology';


function Homepage() {
    return (
    <div className="App">
        <Features />
        <Banner />
        <Products />
        <OpenSource />
        <Technology />
    </div>
    );
  }
  
  export default Homepage;
  