function Header() {
    return (
        <section id="header">
            <div class="container">
                
                <h1 id="logo"><a href="/">Parabolic Dapps</a></h1>
                <p>Decentralized applications that scale with parabolic usage.</p>
                
                <nav id="nav">
                    <ul>
                        <li><a class="icon solid fa-home" href="index.html"><span>Home</span></a></li>
                        <li>
                            <a href="#products" class="icon fa-chart-bar"><span>Products</span></a>
                            <ul>
                                <li><a href="/">Lorem ipsum dolor</a></li>
                                <li><a href="/">Magna phasellus</a></li>
                                <li><a href="/">Etiam dolore nisl</a></li>
                                <li>
                                    <a href="/">Phasellus consequat</a>
                                    <ul>
                                        <li><a href="/">Magna phasellus</a></li>
                                        <li><a href="/">Etiam dolore nisl</a></li>
                                        <li><a href="/">Phasellus consequat</a></li>
                                    </ul>
                                </li>
                                <li><a href="/">Veroeros feugiat</a></li>
                            </ul>
                        </li>
                        <li><a class="icon solid fa-box-open" href="#opensource"><span>Open Source</span></a></li>
                        <li><a class="icon solid fa-cog" href="#technology"><span>Technology</span></a></li>
                        <li><a class="icon solid fa-bullhorn" href="#footer"><span>Socials</span></a></li>
                    </ul>
                </nav>

            </div>
        </section>
    );
}

export default Header;